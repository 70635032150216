<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card :loading="pageLoading" class="rounded-lg">
        <v-card-text>
          <div class="d-flex align-center">
            <v-img
              v-if="$vuetify.theme.dark"
              width="270"
              height="80"
              contain
              src="@/assets/images/logo/logo-light.png"
              alt=""
            />
            <v-img
              v-else
              width="270"
              height="80"
              contain
              src="@/assets/images/logo/logo-dark.png"
              alt=""
            />
          </div>
          <!-- <v-icon>mdi-plus</v-icon> -->
          <div v-if="!hidePoweredBy" class="d-flex align-center justify-center mb-6 mt-3">
            <small class="mr-2 lh-1">PoweredBy</small>
            <img
              v-if="$vuetify.theme.dark"
              height="14"
              src="/img/logo/voy-dark.png"
              alt=""
            />
            <img
              v-else
              height="14"
              src="/img/logo/voy-light.png"
              alt=""
            />
          </div>
          <sign-in-form v-if="!pageLoading" />
        </v-card-text>
      </base-card>
      <v-btn v-if="!inPwa" class="mt-4" dark small text to="/">
        <v-icon left small>mdi-arrow-left</v-icon>
        Voltar para o site
      </v-btn>
      <div class="d-flex">
        <span class="white--text mt-10" style="opacity: 0.7">
          Ao clicar em continuar você está criando uma conta na plataforma
          <img style="height: 12px" src="/img/logo/voy-dark.png" alt="" />
        </span>
      </div>
    </div>
    <v-btn
      icon
      dark
      style="position: absolute; top: 1rem; right: 1rem"
      @click="toggleDarkMode"
    >
      <v-icon>{{
        $vuetify.theme.dark
          ? "mdi-lightbulb-on-outline"
          : "mdi-moon-waning-crescent"
      }}</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SignInForm from "../../../components/global/SignInForm.vue";
import { hidePoweredBy } from "@/themeConfig.js";

export default {
  components: { SignInForm },
  name: "SignIn",
  metaInfo: {
    title: "Acessar conta",
  },
  data() {
    return {
      loading: false,
      pageLoading: true,
      error: false,
      hidePoweredBy,
    };
  },
  methods: {
    ...mapActions(["changeThemeDarkMode"]),
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeThemeDarkMode(this.$vuetify.theme.dark);
    },
    redirect() {
      let to = this.$route.query.redirect || "/app";
      this.$router.push(to);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted() {
    if (!!this.isAuthenticated) this.redirect();
    else return (this.pageLoading = false);
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
